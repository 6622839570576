

.contactimage{
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://images.unsplash.com/photo-1417733403748-83bbc7c05140?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    height: 80vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    overflow: hidden;
}

.contacthero{
    height: 100%;
    width: auto;
   
    
}

.contacttitle{
    padding-top: 200px;
   
    text-align: center;
}

.contacttitle h1{
    color: #ffffff;
    font-family: "Roboto";
    font-size: 36px;
    font-weight: 700;
    line-height: 200px;

    @media screen and (max-width: 991px) {
        font-size: 30px;
      }


}

@media (max-width: 991px){


    .contacttitle h1{
       /*  line-height: 320px; */
       text-align: center;
       padding-top: 50px;
    }
}

