.rowstyletrain{
    padding-top: 50px;
}

.pptitletrain{
    padding: 20px;
    padding-top: 80px;
}

.pptitletrain h2{
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
}


.pptitletrain p{
    font-family: "Roboto";
    line-height: 30px;
    padding-bottom: 10px;
}



.threesectiontrain{
    background-color: #f8f8f8;
}

.trainingaftercards h3{
    font-weight: 600;
    font-family: "Montserrat";
    color: #1e2227;
    padding-bottom: 14px;
}