.founderstitle{
    padding: 20px;

    padding-top: 10px;
}

.founderstitle h2{
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 4px;
    padding-top: 10px;
}

.founderstitle h4{
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 18px;
}

.founderstitle p{
    font-family: "Roboto";
    line-height: 30px;
    padding-bottom: 10px;
}


@media only screen and (max-width: 991px){
    .here{
        height: 360px !important;
    width: 100% !important;
    margin-left: 0px !important;
    }
}