.afterhome-card{
    margin: 50px;
    
   /*  box-shadow: 0 0 10px rgba(190, 189, 189, 0.9); */
    border: none;
}

/* .afterhomebutton{
    background-color: ;
} */

.afterhome-head h3{
 text-align: center;
 font-weight: 600;
 color: #ffffff;
 padding-top: 60px;
}

.afterhome-card h4{
    font-family: "Montserrat";
    font-weight:600;
    color: #212529;
    padding-bottom: 10px;
    
    font-size: 20px;
}

.afterhome-card p{
    font-family: "Roboto";
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 10px;
}

.learn-more {
    position: absolute;
    bottom: 5px; /* Adjust this value as needed */
    padding-bottom: 10px;
}



.learn-more a {
    text-decoration: none;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    color: rgb(2, 130, 221);
}

.wholeafterhome{
    background-color: #074EE2;
    padding-bottom: 50px;
}