.rowstyle{
    padding-top: 50px;
}

.pptitle{
    padding: 20px;
    padding-top: 80px;
}

.pptitle h2{
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
}

.pptitle p{
    font-family: "Roboto";
    line-height: 30px;
    padding-bottom: 10px;
}

/* .rowstyle button{
    background-color: aqua;
    width: 230px;
    height: 50px;
    border-radius: 1.2%;
} */


.descbuttons{
    background-color: antiquewhite;
}

.threesectioback{
    background-color: #f8f8f8;
    padding-top: 50px;
    padding-bottom: 50px;
}

.threebutt button{
    background-color: #f7c513;
    color: #212529;
    font-weight: 500;
    font-family: 'Roboto';
    width: 300px;
    height: 56px;
    font-size: 18px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 8px;
}


@media only screen and (max-width: 991px){
    .threebutt button{
        background-color: #f7c513;
        color: #212529;
        font-weight: 500;
        font-family: 'Roboto';
        width: 100% !important;
        height: 56px;
        font-size: 18px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 8px;
    }
}