.contact-container {
    padding: 50px 0;
  }

  .contact-info {
   
    padding: 20px;
    border-radius: 8px;
  }
  
  .contact-info h3 {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 30px;
  }
  
  .contact-info p {
    margin-bottom: 20px;
    font-family: "Roboto";
    line-height: 30px;
    font-size: 17px;
    padding-bottom: 10px;
  }
  
  .contact-info .address {
    display: flex;
    font-family: "Roboto";
    align-items: center;
    line-height: 22px;  
   

  }
  
  .contact-info .address span {
    white-space: pre-line; /* This will preserve line breaks */
  }
  
 /*  .contact-form {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
  }
  
  .contact-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form label {
    font-weight: bold;
  }
  
  .contact-form textarea {
    resize: none;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
  
  .contact-form label {
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .contact-form button {
    background-color: #F7C513;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  } */

  .contact-form {
    background-color: whitesmoke;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 600px; /* Adjust the maximum width as needed */
    margin: 0 auto; /* Center the form horizontally */
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button {
    background-color: #f7c513;
    height: 50px;
    width: 200px;
    font-family: "Roboto";
    font-weight: 500;
    border-radius: 8px;
    text-align: center;
  }
  
  .contact-form button:hover {
    background-color: #e0b208;
  }

  .toast {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .toast.show {
    opacity: 1;
  }