.rowstylecoach{
    padding-top: 50px;
}

.pptitlecoach{
    padding: 20px;
    padding-top: 80px;
}

.pptitlecoach h2{
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
}


.pptitlecoach p{
    font-family: "Roboto";
    line-height: 30px;
    padding-bottom: 10px;
}



.threesectioncoach{
    background-color: #f8f8f8;
    padding-top: 50px;
    padding-bottom: 50px;
}