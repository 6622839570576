.logoconsult{
    padding-top: 30px;
    padding-bottom: 60px;
   
}

.logotitleconsult{
    text-align: center;
    padding-top: 60px;
}

.logotitleconsult h3{
    font-weight: 600;
    font-family: "Montserrat";
}

.logotitleconsult p{
    font-family: "Roboto";
    font-size: 20px;
}

.brandcard{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
    transition:  0.6s;
}

.brandcard:hover{
    transform: scale(1.1);
    z-index: 1;
}